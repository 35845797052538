.c-avatar-editor {
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-input-range {
  -webkit-appearance: none;
  vertical-align: middle;
  outline: none;
  border: none;
  padding: 0;
  background: none;
}

.c-input-range::-webkit-slider-runnable-track {
  background-color: var(--g-color-brand-tertiary);
  height: 6px;
  border-radius: var(--g-border-radius-pill);
  border: 1px solid transparent;
}

.c-input-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  border-radius: 100%;
  border: 1px solid var(--g-color-brand-tertiary);
  background-color: var(--g-color-brand-tertiary);
  cursor: pointer;
  height: var(--g-spacing);
  width: var(--g-spacing);
  margin-top: -6px;
}

.c-input-range::-moz-range-progress,
.c-input-range::-moz-range-thumb {
  background-color: var(--g-color-brand-tertiary);
  border: 1px solid var(--g-color-brand-tertiary);
  height: 0.35rem;
}

.c-input-range::-moz-range-progress {
  border-radius: var(--g-border-radius-pill);
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.c-input-range::-moz-range-thumb {
  height: var(--g-spacing-small);
  width: var(--g-spacing-small);
}

.c-input-range::-moz-range-track {
  background-color: var(--g-color-grey-200);
  border-radius: var(--g-border-radius-pill);
  border: 1px solid transparent;
  height: var(--g-spacing-2x-small);
}

.c-input-range::-ms-thumb {
  -webkit-appearance: none !important;
  border-radius: 100%;
  height: 12px;
  width: 12px;
  margin-top: -5px;
}

.c-input-range::-ms-fill-lower {
  background-color: var(--g-color-grey-200);
}

.c-input-range::-ms-fill-upper {
  background-color: var(--g-color-brand-tertiary);
}

.c-sidebar-section__rotate {
  display: flex;
  gap: var(--g-spacing-small);
}

.c-rotate-icon--left {
  transform: rotate(90deg);
  width: var(--g-spacing-3x-large);
  height: var(--g-spacing-3x-large);
}

.c-rotate-icon--right {
  transform: rotate(270deg);
  width: var(--g-spacing-3x-large);
  height: var(--g-spacing-3x-large);
}

.c-preview-image-dialog {
  max-height: calc(var(--g-dialog-max-height) * 2);
}

.c-image-sidebar-edit-image-view__transparent-image .c-label {
  display: block;
}
