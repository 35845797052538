.switcher {
  background-color: var(--g-color-white);
  border-radius: 2em;
  position: fixed;
  left: 50%;
  padding: var(--g-spacing-x-small) var(--g-spacing-x-large);
  transform: translateX(-50%);
  top: var(--g-spacing-x-small);
  z-index: 2;
}

.container {
  background-color: #212121;
  padding-top: 4rem;
  height: 100vh;
  width: 100%;
}

.preview {
  background-color: var(--g-color-white);
  border-radius: var(--g-border-radius-medium);
  box-shadow: 0 0 var(--g-spacing-small) 0 rgba(0, 0, 0, 0.3);
  margin: 0 auto;
  transition: width 0.6s;
}
