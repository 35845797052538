.c-operating-hours {
  list-style: none;
}

.c-operating-hours__item {
  align-items: center;
  border: 1px solid var(--g-color-grey-200);
  color: var(--g-color-grey-200);
  display: flex;
  font-family: 'calibri';
  font-weight: var(--g-font-weight-light);
  justify-content: space-between;
  margin: 0;
  padding: var(--g-spacing-2x-small) var(--g-spacing-small);
}

.c-operating-hours__item:first-child {
  border-top-left-radius: var(--g-border-radius-small);
  border-top-right-radius: var(--g-border-radius-small);
}

.c-operating-hours__item:last-child {
  border-bottom-left-radius: var(--g-border-radius-small);
  border-bottom-right-radius: var(--g-border-radius-small);
}

.c-operating-hours__details {
  align-items: center;
  display: flex;
  gap: 1rem;
}

.c-operating-hours__text {
  color: var(--g-color-black-900);
  font-family: 'calibri';
  font-weight: var(--g-font-weight-light);
  margin: 0;
}

.c-operating-hours-container {
  display: flex;
  justify-content: flex-end;
}
