.c-interactive-region-preview {
  justify-content: center;
  position: relative;
  display: flex; 
  width: 100%;
}

.c-interactive-region-preview__image {
  height: auto;
  width: 100%;
};

.c-interactive-region-preview__item {
  position: absolute;
}

.c-interactive-region-preview__item--small {
  height: calc(var(--g-spacing) / 2);
  width:  calc(var(--g-spacing) / 2 );
}

.c-interactive-region-preview__item--medium {
  height: calc(var(--g-spacing-x-large) / 2);
  width:  calc(var(--g-spacing-x-large) / 2 );
}

.c-interactive-region-preview__item--large {
  height: calc(var(--g-spacing-2x-large) / 2);
  width:  calc(var(--g-spacing-2x-large) / 2 );
}