/* stylelint-disable selector-class-pattern */
.c-sidebar-container {
  padding-left: var(--g-spacing-small);
  padding-right: var(--g-spacing-small);
}

.c-sidebar-container--padding-ends {
  padding-bottom: var(--g-spacing-small);
  padding-top: var(--g-spacing-small);
}
