/* stylelint-disable selector-class-pattern */

.c-transcript-overview__icon {
  color: var(--g-color-grey-500);
  height: var(--g-spacing);
  width: var(--g-spacing);
}

.c-transcript-overview__item {
  display: flex;
  justify-content: center;
  width: 100%;
}