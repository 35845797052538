.c-asset-sidebar-caption-view__caption-item {
  align-items: center;
  background-color: var(--g-color-white);
  border: var(--g-border-primary);
  border-left-width: 6px;
  border-bottom: none;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  display: flex;
  gap: var(--g-spacing-x-small);
  justify-content: space-between;
  padding: var(--g-spacing-x-small) var(--g-spacing);
  width: 100%;
  margin: 0;
}

.c-asset-sidebar-caption-view__caption-title {
  width: 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.c-asset-sidebar-caption-view__caption-time {
  flex: 1;
}

.c-asset-sidebar-caption-view__caption-item:first-child {
  border-top-left-radius: var(--g-border-radius-small);
  border-top-right-radius: var(--g-border-radius-small);
}

.c-asset-sidebar-caption-view__caption-item:last-child {
  border-bottom: var(--g-border-primary);
  border-bottom-left-radius: var(--g-border-radius-small);
  border-bottom-right-radius: var(--g-border-radius-small);
}

.c-asset-sidebar-caption-view__caption-item:hover {
  border-left: 6px solid var(--g-color-brand-tertiary);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.c-asset-sidebar-caption-view__add {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  position: relative;
}

.c-asset-sidebar-caption-view__add-icon {
  height: var(--g-spacing-2x-large);
  width: var(--g-spacing-2x-large);
  position: absolute;
  right: 0;
}
