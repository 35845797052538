.c-report-dashboard-container {
  border: 1px solid var(--g-color-grey-100);
  border-radius: var(--g-border-radius-small);
  background-color: var(--g-color-white);
  padding: var(--g-spacing);
}

.c-report-dashboard__item {
  font-weight: var(--g-font-weight-light);
  margin-left: 0;
}