.c-asset-sidebar-usage-view__usage-item {
  align-items: center;
  background-color: var(--g-color-white);
  border: var(--g-border-primary);
  border-left-width: 6px;
  border-bottom: none;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  display: flex;
  justify-content: space-between;
  padding: var(--g-spacing-x-small) var(--g-spacing);
  width: 100%;
  margin: 0;
}

.c-asset-sidebar-usage-view__usage-item:first-child {
  border-top-left-radius: var(--g-border-radius-small);
  border-top-right-radius: var(--g-border-radius-small);
}

.c-asset-sidebar-usage-view__usage-item:last-child {
  border-bottom: var(--g-border-primary);
  border-bottom-left-radius: var(--g-border-radius-small);
  border-bottom-right-radius: var(--g-border-radius-small);
}

.c-asset-sidebar-usage-view__usage-item:hover {
  border-left: 6px solid var(--g-color-brand-tertiary);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
