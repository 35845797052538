/* stylelint-disable selector-class-pattern */
.c-posts-table__attachment-image {
  max-width: 10rem;
}


.c-posts-table__attachment-icon {
  height: var(--g-spacing-2x-large);
  width: var(--g-spacing-2x-large);
}

.c-posts-table__attachment-button {
  border: 0;
  padding: 0;
}
