.c-status-button {
  background-color: transparent;
  border: none;
}

.c-status-icon {
  align-items: center;
  border-radius: 50%;
  display: inline-flex;
  height: var(--g-spacing);
  justify-content: center;
  margin-right: var(--g-spacing-2x-small);
  position: relative;
  top: 0.2em;
  width: var(--g-spacing);
}

.c-status-icon--published {
  background-color: var(--g-color-blue-100);
}

.c-status-icon--unpublished {
  background-color: var(--g-color-red-400);
}

.c-status-icon--pending {
  background-color: var(--g-color-blue-300);
}

.c-phone-link {
  color: var(--g-color-blue-200) !important;
}

.c-link {
  color: var(--g-color-blue-200) !important;
  height: var(--g-spacing-large);
  width: var(--g-spacing-large);
}

.c-minus-icon {
  height: var(--g-spacing-large);
  width: var(--g-spacing-large);
}

.c-avatar {
  font-size: unset;
}
