.c-report-selector {
  display: flex;
  flex-wrap: wrap;
  gap: var(--g-spacing-small);
}

.c-report-selector li {
  margin: 0;
}

.c-report-selector__item {
  border: 1px solid var(--g-color-grey-100);
  border-radius: var(--g-border-radius-medium);
  display: block;
  min-width: 5rem;
  text-align: center;
  padding: var(--g-spacing-2x-small) var(--g-spacing-large);
}

.c-report-selector__item:hover,
.c-report-selector__item:focus,
.c-report-selector__item.is-active {
  background-color: var(--g-color-black);
  border-color: var(--g-color-black);
  color: var(--g-color-white);
  box-shadow: none;
  transition: all 0.3s ease-in-out;
}

.c-report-selector__item.is-active:hover,
.c-report-selector__item.is-active:focus {
  background-color: var(--g-color-white);
  border-color: var(--g-color-grey-100);
  color: var(--g-color-black);
  box-shadow: none;
}


.c-report-selector__item .c-button__text {
  font-weight: var(--g-font-weight-light);
}

.c-report-selector__item .c-button__icon {
  height: var(--g-spacing);
  width: var(--g-spacing);
}
