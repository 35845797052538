/* stylelint-disable selector-class-pattern */
.c-scenario-sidebar-templates-view {
  padding-bottom: var(--g-spacing-6x-large);
}

.c-scenario-sidebar-templates-view__ab-toggle .c-label {
  position: relative;
  top: calc(var(--g-spacing-x-small) * -1);
}

.c-scenario-sidebar-templates-view__counter {
  display: flex;
  gap: var(--g-spacing-small);
}

.c-scenario-sidebar-templates-view__preview-button {
  background-color: var(--g-color-white);
  border: none;
  cursor: pointer;
  position: relative;
  top: calc(var(--g-spacing-3x-small));
}

.c-scenario-sidebar-templates-view__preview-button .c-icon {
  height: var(--g-spacing);
  width: var(--g-spacing);
}

.c-scenario-sidebar-templates-view__templates-list {
  margin: 0;
  max-height: 15.35rem;
  overflow-y: auto;
}

.c-scenario-sidebar-templates-view__templates-item {
  align-items: center;
  background-color: var(--g-color-white);
  border: var(--g-border-primary);
  border-bottom: none;
  display: flex;
  justify-content: space-between;
  padding: var(--g-spacing-x-small);
  width: 100%;
}

.c-scenario-sidebar-templates-view__templates-item:first-child {
  border-top-left-radius: var(--g-border-radius-small);
  border-top-right-radius: var(--g-border-radius-small);
}

.c-scenario-sidebar-templates-view__templates-item--one {
  border: var(--g-border-primary);
  border-radius: var(--g-border-radius-small);
}

.c-scenario-sidebar-templates-view__templates-item__title {
  padding-left: var(--g-spacing-x-small);
}

.c-scenario-sidebar-templates-view__templates-item__total {
  display: flex;
  gap: var(--g-spacing);
  padding-right: 5.5rem;
}

.c-scenario-sidebar-templates-view__templates-item__total span:first-child {
  font-weight: var(--g-font-weight-bold);
}

.c-scenario-sidebar-templates-view__templates-item--total {
  background-color: var(--g-color-grey-100);
  border-radius: 0 !important;
  border-bottom-left-radius: var(--g-border-radius-small);
  border-bottom-right-radius: var(--g-border-radius-small);
  border-top: none;
  display: flex;
  gap: var(--g-spacing-x-small);
}

.c-scenario-sidebar-templates-view__search-section {
  position: relative;
}

.c-scenario-sidebar-templates-view__tooltip {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
