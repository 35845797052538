/* stylelint-disable selector-class-pattern */
.c-dropdown-navigation__list,
.c-dropdown-navigation__list-item {
  margin-bottom: 0;
}

.c-dropdown-navigation__list-item-button {
  font-size: var(--g-font-size);
  padding: var(--g-spacing-2x-small) 0;
}

/* TODO: Remove this once this added to shared library */
.c-sidebar-buttons {
  position: fixed;
  right: 0;
  left: unset;
  width: var(--c-sidebar-max-width);
}

.c-sidebar-section--aligned {
  display: flex;
  flex-direction: column;
  position: relative;
}

.c-sidebar-section__tooltip {
  position: absolute;
  right: 0;
  top: 0;
}

.c-footer {
  z-index: 2;
}

/* TODO: Remove this once contact page is added */
.c-page-message__content-button-group .c-link-button:last-child {
  display: none;
}

/* TODO: @ash move these to ui library styling */

.c-sidebar-view .c-icon-radio-group__container {
  display: grid;
  grid-template-columns: repeat(4, minmax(132px, 1fr));
}

.c-sidebar-view .c-icon-radio-group__container .c-icon-radio__icon-container {
  justify-content: flex-start;
  width: 100%;
}

.c-tablist__nav {
  padding: 0 var(--g-spacing-2x-large) 0 var(--g-spacing-small);
}

.c-sidebar-container .c-information-box {
  background-color: var(--g-color-grey-50);
}

.c-sidebar-container .c-search-filter .c-add-on__icon {
  color: var(--g-color-black) !important;
  height: var(--g-spacing-large);
  width: var(--g-spacing-large);
}

/* TODO: @ash end */

.c-card--small .c-settings-button__icon {
  width: var(--g-spacing-small);
}

.h-display-none {
  display: none;
}

.c-video-card__body--medium .c-video-player__play-icon-container {
  height: var(--g-spacing-4x-large);
}

.c-video-card__body--small .c-video-player__play-icon-container {
  height: var(--g-spacing-2x-large);
  position: relative;
  top: var(--g-spacing-3x-small);
}

.c-play-button--medium .c-icon {
  height: var(--g-spacing-3x-large);
  width: var(--g-spacing-3x-large);
}

.c-play-button--small .c-play-button__play-icon {
  padding: 1px;
  height: 1.2rem;
  width: var(--g-spacing-small);
}

/* TODO: Remove this once this added to shared library */
.c-card__container__logo {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}

/* TODO: Remove this once this added to shared library */
.c-card__container__logo img {
  max-height: var(--g-spacing-6x-large);
  padding: var(--g-spacing-2x-small);
}

/* TODO: Remove this once this added to shared library */
.c-app-header__end .c-organisation-navigation__icon {
  padding-top: 0;
  top: 0;
}

.c-sidebar-save-button {
  background-color: var(--g-color-grey-50);
  bottom: 0;
  display: flex;
  gap: var(--g-spacing);
  justify-content: center;
  padding: var(--g-spacing);
  position: fixed;
  right: 0;
  width: var(--c-sidebar-max-width);
}

.copy-code-button {
  border: none;
  border-radius: var(--g-spacing-2x-small);
  display: flex;
  align-items: center;
  padding: var(--g-spacing-2x-small);
}

.copy-code-button:focus {
  box-shadow: var(--g-focus-primary);
}

.copy-code-button__icon {
  height: var(--g-spacing-x-large);
  width: var(--g-spacing-x-large);
}

.h-display-block {
  display: block;
}

.c-page-container {
  max-width: 125rem;
}

.c-asset-list-toggle .c-toggle-switch__slider:before {
  left: 0;
}

/* TODO: Remove this once this added to shared library */
@media screen and (max-width: 26.5em) {
  .c-sidebar-buttons {
    width: calc(var(--c-sidebar-max-width) - 8rem);
  }
}

@media (min-width: 64em) {
  .c-chart-picker:not(.c-chart-picker--full-width) .c-barchart__chart,
  .c-chart-picker:not(.c-chart-picker--full-width) .c-linechart__chart {
    max-width: 28rem;
  }
}

.c-table-row-action__text {
  font-size: var(--g-font-size);
  font-family: var(--g-font-primary);
  font-weight: var(--g-font-weight-light);
}

@media (min-width: 28em) {
  .c-sidebar-nav__page-wrapper.is-pushed-right {
    margin-left: calc(var(--c-left-sidebar-max-width) - calc(var(--g-spacing) * 8));
  }
}

.c-table-page-template .c-page-container > .l-container,
.c-app-template-page .c-page-container > .l-container,
.c-basic-page-layout .c-page-container > .l-container,
.c-card-list-page-template .c-page-container > .l-container,
.c-project-dashboard__page-layout .c-page-container > .l-container {
  display: block;
}

.c-table__header-icon {
  height: var(--g-spacing);
  width: var(--g-spacing);
}

.c-progress-tracker-details {
  align-items: flex-end;
  display: flex;
  gap: var(--g-spacing-small);
  font-family: var(--g-font-primary);
  font-size: var(--g-font-size);
}

.c-progress-tracker-details__icon {
  height: var(--g-spacing);
  width: var(--g-spacing);
}

.c-progress-tracking-user__additional-details {
  font-size: var(--g-font-size);
  line-height: 1.5;
  border: none;
  padding: var(--g-spacing-2x-small);
  color: var(--g-color-brand-tertiary);
}

.c-progress-tracker-details__icon--purple {
  color: var(--g-color-blue-800);
}
.c-progress-tracker-details__icon--green {
  color: var(--g-color-green-300);
}
.c-progress-tracker-details__icon--blue {
  color: var(--g-color-blue-200);
}
.c-progress-tracker-details__icon--default {
  color: var(--g-color-grey-300);
}
.c-progress-tracker-details__icon--red {
  color: var(--g-color-red-400);
}

.c-progress-tracker__item dd {
  line-height: 1;
  margin: 0;
}

.c-progress-tracker__item dt {
  text-transform: uppercase;
  line-height: 1;
  font-size: var(--g-font-size-x-small);
  font-weight: var(--g-font-weight-bold);
}

.c-role-indicator {
  position: absolute;
  top: 0;
}

.c-admin-star {
  position: absolute;
  right: 0;
  transform: translateY(-50%);
}

.c-admin-star .c-tooltip__trigger {
  background: transparent;
  border: none;
  height: var(--g-spacing);
  width: var(--g-spacing);
}

.c-admin-star .c-tooltip__trigger .c-tooltip__icon {
  background: transparent;
  padding: 0;
  height: var(--g-spacing);
  width: var(--g-spacing);
  color: #ffc96c;
}

.c-admin-star--is-admin .c-tooltip__trigger .c-tooltip__icon {
  color: #72acff;
}

.c-label--large {
  font-size: var(--g-font-size);
}

.c-sidebar-toggle .c-label {
  justify-content: space-between;
}

.c-sidebar-toggle {
  width: 100%;
}

.c-report-toggle .c-toggle-switch__slider::before {
  left: 0;
}

.c-report-datepicker {
  min-width: 15rem;
}

.c-report-datepicker .DateRangePickerInput,
.c-report-datepicker .DateRangePicker {
  width: 100%;
}

.c-report-step-select {
  max-width: 22rem;
  min-width: 18.75rem;
}

.c-report-heading-select {
  min-width: 18.75rem;
}

@media only screen and (min-width: 78em) {
  .c-report-heading-select {
    flex-grow: 1;
  }
}

.c-icon-radio--has-label .c-icon-radio__icon-container {
  padding: 0 var(--g-spacing-x-small);
}

.h-rotate-right {
  transform: rotate(-90deg);
}

@media (max-width: 38em) {
  .c-sidebar__container--is-hidden--left {
    transform: translateX(-100%);
  }
}

.c-sidebar-nav__item {
  font-size: var(--g-font-size);
}

.c-content-builder-container {
  display: block !important;
}

.c-report-download-button {
  /* required a specific size to align  with text button heights */
  padding: 0.399rem var(--g-spacing-small);
}

/* Content builder preview */
/* stylelint-disable selector-class-pattern */
.c-cms-entity-container {
  border: 1px solid transparent;
}

.c-cms-entity-container__header {
  align-items: center;
  background-color: var(--g-color-blue-200);
  display: flex;
  gap: var(--g-spacing);
  opacity: 0;
  transition: 0.1s ease-in-out;
  padding: var(--g-spacing-2x-small);
  width: fit-content;
}

.c-cms-entity-container:hover,
.c-cms-entity-container.is-highlighted {
  border: 1px solid var(--g-color-blue-200);
}

.c-cms-entity-container.is-highlighted > .c-cms-entity-container__header,
.c-cms-entity-container:hover > .c-cms-entity-container__header {
  opacity: 100%;
}

.c-cms-entity-container__title {
  font-size: var(--g-font-size-small);
  margin: 0;
}

.c-cms-entity-container__settings .c-button-icon-dropdown__icon {
  color: var(--g-color-blue-700);
}

.c-cms-entity-container__settings .c-button-icon-dropdown__trigger-icon {
  background-color: var(--g-color-white);
}

.c-cms-entity-container__settings:hover .c-button-icon-dropdown__trigger-icon {
  background-color: var(--g-color-grey-100);
}

.c-cms-entity-container__settings .c-button-icon-dropdown__menu {
  top: calc(var(--g-spacing-x-small) * -1);
  left: var(--g-spacing-x-large);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.c-sidebar-color-picker .c-input-field__inner {
  justify-content: flex-end;
}

.c-content-builder__edit-button {
  border: 1px solid var(--g-color-grey-100);
}

.c-content-builder__edit-button:hover,
.c-content-builder__edit-button:focus,
.c-content-builder__edit-button.is-active {
  background-color: var(--g-color-black);
  border-color: var(--g-color-black);
  color: var(--g-color-white);
  box-shadow: none;
}

.c-content-builder__edit-button.is-active:hover,
.c-content-builder__edit-button.is-active:focus {
  background-color: var(--g-color-white);
  border-color: var(--g-color-grey-100);
  color: var(--g-color-black);
  box-shadow: none;
}

.c-content-builder__edit-button--is-external .c-button__icon {
  transform: rotate(-35deg);
}

.c-content-builder__edit-button .c-button__text {
  font-weight: var(--g-font-weight-light);
}

.c-content-builder__edit-button .c-button__icon {
  height: var(--g-spacing);
  width: var(--g-spacing);
}

.c-cartoon__bubble {
  animation: none !important;
  opacity: 1 !important;
}

.c-language-filter__tooltip .c-tooltip__bubble {
  transform: translateY(-5%);
}

.c-cartoon-slide p {
  margin: 0;
}

.c-character-picker .c-select-with-search__content {
  z-index: 4;
}

.c-bulk-upload__image {
  color: #000;
  fill: #000;
  height: 3rem;
  width: 3rem;
}

.c-card-list-page-template .c-toggle-switch__wrapper--inline .c-label__text {
  white-space: nowrap;
}

.c-content-builder-bulk-actions__button,
.c-content-builder-sidebar-nav__item-settings .c-button-icon-dropdown__trigger-button {
  padding: var(--g-spacing-3x-small) var(--g-spacing);
}

.c-button-icon-dropdown {
  z-index: 4;
}

.c-content-builder-sidebar-nav__item-settings {
  z-index: 5 !important;
}

.c-content-builder-sidebar-nav__add-new {
  z-index: 1;
}

.c-cms-entity-container .c-button-icon-dropdown__menu {
  min-width: 11rem;
}

.c-toolbar__item > .c-input-field {
  width: 100%;
}

.h-word-break {
  word-wrap: break-word;
}

.c-progress-tracking-type-icon,
.c-impersonated-tick-icon {
  color: var(--g-color-grey-300);
  height: var(--g-spacing);
  width: var(--g-spacing);
}

.c-sidebar-icon-radio-group .c-input-field__inner {
  justify-content: flex-end;
}

.c-email-template-builder__preview .c-sidebar-nav__page-wrapper {
  margin-right: var(--c-sidebar-max-width);
  transition: margin-right var(--g-transition-time) ease-in-out;
}

.c-tooltip__bubble {
  text-align: left;
}

.c-interaction-report-table .c-table__thead-th-contents-text {
  white-space: break-spaces;
}

.c-condition-table .c-table__tbody {
  border: 0;
}

.c-condition-table .c-table__tbody-tr:nth-child(odd) .c-table__tbody-td:last-child {
  border-right: 1px solid var(--g-color-grey-100);
}

.c-condition-table .c-table__tbody-tr:nth-child(odd) .c-table__tbody-td:first-child > .c-table__tbody__td-content:first-child {
  border-left: 1px solid var(--g-color-grey-100);
}

.c-condition-table .c-table__tbody-tr:nth-child(odd):hover .c-table__tbody-td:first-child > .c-table__tbody__td-content:first-child {
  border-left: 1px solid var(--g-color-blue-200);
}

.c-condition-table .c-table__tbody-tr:nth-child(even) .c-table__tbody-td:first-child > .c-table__tbody__td-content:first-child {
  border-left: 0;
  padding-left: 0;
}

.c-condition-table .c-table__tbody-tr {
  border-bottom: 1px solid var(--g-color-grey-100);
}

.c-condition-table .c-table__tbody-tr:nth-child(even):hover {
  background-color: var(--g-color-white);
}

.c-slider-items-overview .c-input-field__inner > .c-spacing {
  width: 100%;
}


.c-scenario-start-date .c-tooltip__bubble--right {
  transform: translateY(-5%);
}

.c-favorite-button {
  flex-shrink: 0;
}

.c-condition-input--small {
  max-width: 8rem;
}

.c-summary-feedback-icon {
  color: var(--g-color-grey-500);
  height: var(--g-spacing);
  width: var(--g-spacing);
}
