.c-layout-selector-group {
  border: none;
  padding: 0;
}

.c-layout-selector__radios {
  display: flex;
  flex-wrap: wrap;
  gap: var(--g-spacing);
}

.c-layout-selector {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  flex: 1;
  min-width: 130px; /* Set a minimum width */
  flex-basis: 130px; /* Set a base width */
  max-width: 130px; /* Optional: Set a maximum width */
  box-sizing: border-box;
  text-align: center;
}

.c-layout-selector__input {
  left: -9999px;
  position: absolute;
}

.c-layout-selector__icon-container {
  display: flex;
  gap: var(--g-spacing-2x-small); /* Gap between icons */
  justify-content: center;
  align-items: center;
}

.c-layout-selector__icon {
  background-color: var(--g-color-grey-100);
  border-radius: 2px;
  content: '';
  height: var(--g-spacing-6x-large);
  transition: var(--g-animation-transition);
}


.c-layout-selector.is-selected .c-layout-selector__icon {
  background-color: var(--g-color-brand-tertiary);
}
