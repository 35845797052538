:root {
  /* FONTS */
  --g-font-primary: 'calibri';
  --g-font-secondary: 'rift';

  /* jodit editor */
  --jd-color-panel: var(--g-color-white);
}

.jodit-source__mirror {
  background-color: var(--g-color-white) !important;
  color: var(--g-color-black) !important;
}

html {
  font-size: 0.875rem;
  scroll-behavior: smooth;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
    sans-serif;
  overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  font-family: var(--g-font-primary);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-header);
  margin-bottom: 1.5rem;
  margin-top: 0;
}

h1,
.h1 {
  font-size: 3rem;
  line-height: 1;
}

h2,
.h2 {
  font-size: 2.25rem;
  line-height: 1.15;
}

h3,
.h3 {
  font-size: 1.25rem;
  line-height: 1.2;
}

h4,
.h4 {
  font-size: 1rem;
  line-height: 1.2;
}

p {
  line-height: 1.5;
  margin-top: 0;
}

ul {
  margin-top: 0;
}

li {
  margin-bottom: var(--spacing-1);
}

button {
  background-color: transparent;
  line-height: 1.3;

  /* TODO: Remove this after adding below to the shared library component */
  cursor: pointer;
}

img {
  max-width: 100%;
}

fieldset {
  border: none;
  margin: 0;
}

table {
  border-collapse: collapse;
}

th {
  margin: 0;
  padding: 0;
}

@media (min-width: 768px) {
  html {
    font-size: 1rem;
  }

  h1,
  .h1 {
    font-size: 4.5rem;
    line-height: 1;
  }

  h2,
  .h2 {
    font-size: 3.5rem;
    line-height: 1.1;
  }

  h3,
  .h3 {
    font-size: 1.5rem;
    line-height: 1.3;
  }

  h4,
  .h4 {
    font-size: 1.15rem;
    line-height: 1.4;
  }
}

.c--sidebar-close {
  display: flex;
  justify-content: flex-end;
  padding: var(--g-spacing-x-large) 0;
}

.c--sidebar {
  display: flex;
}

.c--items-add {
  padding: var(--g-spacing-x-large) 0;
  display: flex;
  justify-content: flex-end;
}

.c-back-button {
  margin-top: var(--g-spacing-x-large);
}

.c-input-field > .c-select-list {
  width: 100%;
}

.notification-groups-user-selection {
  max-height: 40vh;
  overflow-x: hidden;
}
.notification-group-selected-users-list > li {
  display: flex;
}
.notification-group-button-select-unselect {
  margin-bottom: 10px;
}

.insight-card {
  width: 100%;
  min-height: 20rem;
}

.insight-card.xl {
  width: 100%;
  position: relative;
  height: auto;
}

.c-icon--large {
  height: 3rem;
  width: 3rem;
}


.l-container {
  display: flex;
}

.c-file-link {
  align-items: center;
  display: flex;
  gap: 0.25rem;
  color: var(--g-color-blue-300);
}

.c-file-link__icon {
  height: 1rem;
  width: 1rem;
}


.c-avatar-title {
  align-items: center;
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -ms-grid;
  display: grid;
  grid-template-columns: var(--g-spacing-3x-large) auto;
  -ms-grid-columns: var(--g-spacing-3x-large) auto;
  position: relative;
}

.c-sidebar__container--left {
  padding-bottom: var(--g-spacing-6x-large);
}