.c-toggle-buttons {
  display: flex;
}

.c-toggle-button__input {
  left: -9999rem;
  position: absolute;
  top: -9999rem;
}

.c-toggle-button__input + label {
  background-color: var(--g-color-grey-100);
  border: 1px solid var(--g-color-brand-grey-100);
  color: var(--g-color-black-900);
  cursor: pointer;
  margin-right: calc(var(--g-spacing-2x-small) * -1);
  min-width: 2.125rem;
  padding: var(--g-spacing-x-small) var(--g-spacing);
  text-align: center;
}

.c-toggle-button__input--first + label:first-of-type {
  border-radius: var(--g-spacing-x-small) 0 0 var(--g-spacing-x-small);
}

.c-toggle-button__input--last + label:last-of-type {
  border-radius: 0 var(--g-spacing-x-small) var(--g-spacing-x-small) 0;
}

.c-toggle-button__input:checked + label {
  background-color: var(--g-color-brand-tertiary);
  border: var(--g-color-brand-tertiary);
}
