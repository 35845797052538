.c-page-title-banner {
  background-image: url(/images/background-gradient-minimal.svg);
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  padding-bottom: 8rem;
  padding-top: 12rem;
}

.c-page-title-banner__container {
  animation: fadeUp 0.8s ease-out 0.2s forwards;
  margin-bottom: auto;
  margin-top: auto;
  text-align: center;
  min-height: 12rem !important;
}

.c-page-title-banner__container__header {
  color: var(--g-color-white);
  margin-bottom: var(--g-spacing);
}
