/* stylelint-disable selector-class-pattern */

.c-condition-timespan {
  display: flex;
  flex-direction: column;
  position: relative;
}

.c-condition-timespan__inputs {
  align-items: center;
  display: flex;
  gap: var(--g-spacing);
}

.c-condition-timespan__input-wrapper {
  align-items: center;
  display: flex;
  gap: var(--g-spacing-x-small);
}

.c-condition-timespan__input-wrapper .c-input__label {
  display: none;
}

.c-condition-timespan__input {
  max-width: var(--g-spacing-6x-large);
}

.c-condition-timespan__tooltip {
  position: absolute;
  right: 0;
  top: 0;
}

