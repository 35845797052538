/* stylelint-disable selector-class-pattern */
.c-region-picker {
  cursor: crosshair;
  height: 100%;
  overflow: hidden;
  width: 100%;
}

.c-region-picker__image {
  height: 100%;
  width: 100%;
}

.c-region-picker.is-disabled {
  cursor: not-allowed;
}