/* stylelint-disable selector-class-pattern */
.c-status-pill {
  border: 1px solid #FA6980;
  background-color: #FA6980;
  border-radius: var(--g-border-radius-small);
  color: #fff;
  padding: var(--g-spacing-3x-small) var(--g-spacing-2x-small);
}

.c-status-pill--highlighted {
  border: 1px solid #4FBD9C;
  background-color: #4FBD9C;
}

.c-status-pill--hollow-red {
  background-color: var(--g-color-white);
  color: #FA6980;
  border: 1px solid #FA6980;
}

.c-status-pill--hollow-grey {
  background-color: var(--g-color-white);
  color: #262542;
  border: 1px solid #262542;
}

.c-status-indicator__status {
  font-size: var(--g-font-size-x-small);
  text-transform: capitalize;
}
