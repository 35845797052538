.c-asset-sort-select {
  min-width: 18rem;
}

.c-asset-list__view-toggle {
  display: flex;
}

.c-asset-list__view-toggle__button {
  border: none;
  cursor: pointer;
  padding: 0;
  margin-right: var(--g-spacing-small);
}

.c-asset-list__view-toggle__icon {
  color: var(--g-color-grey-300);
}

.c-asset-list__view-toggle__icon-grid-selected,
.c-asset-list__view-toggle__icon-list-selected {
  color: var(--g-color-brand-tertiary);
}

.c-asset-list__view-toggle__icon {
  height: var(--g-spacing-x-large);
  width: var(--g-spacing-x-large);
}

.c-asset-list .c-filter-bar__body {
  padding: var(--g-spacing-small) var(--g-spacing-2x-large) var(--g-spacing-x-small);
}

.c-asset-list .c-filter-bar__results-button {
  padding-top: var(--g-spacing-x-small);
}

.c-favorite-images-toggle .c-label,
.c-favorite-audios-toggle .c-label,
.c-favorite-videos-toggle .c-label,
.c-favorite-documents-toggle .c-label {
  align-items: center;
  display: flex;
  flex-direction: row;
  font-weight: var(--g-font-weight-semibold);
  font-size: var(--g-font-size-small);
  gap: var(--g-spacing-small);
}

.c-favorite-images-toggle .c-label .h-spacing-small,
.c-favorite-audios-toggle .c-label .h-spacing-small,
.c-favorite-videos-toggle .c-label .h-spacing-small,
.c-favorite-documents-toggle .c-label .h-spacing-small {
  margin-bottom: 0;
  position: relative;
  top: var(--g-spacing-3x-small);
}

.c-select-list__label[for='image-sort'],
.c-select-list__label[for='audio-sort'],
.c-select-list__label[for='document-sort'],
.c-select-list__label[for='video-sort'] {
  display: none;
}

.c-image-list-item,
.c-audio-list-item,
.c-document-list-item,
.c-video-list-item {
  margin-bottom: 0;
}

.c-image-list--small .c-card-list,
.c-audio-list--small .c-card-list,
.c-document-list--small .c-card-list {
  gap: var(--g-spacing-x-small);
}

.c-audio-list-item__title-section .c-audio-player__figure,
.c-video-list-item__title-section .c-video-player__figure {
  margin: 0;
}

.c-audio-list-item__title-section .c-audio-player__audio,
.c-video-list-item__title-section .c-video-player__video {
  width: 100%;
}

.c-audio-card__body__favorite-button--small .c-audio-card__body__favorite-icon,
.c-image-card__body__favorite-button--small .c-image-card__body__favorite-icon {
  position: relative;
  bottom: -0.4rem;
  height: var(--g-spacing-x-large);
  width: var(--g-spacing-x-large);
}

.c-audio-list-item__asset-player--small .c-audio-player__audio {
  position: relative;
  top: var(--g-spacing-2x-small);
}

.c-document-list__document-card {
  padding: var(--g-spacing) var(--g-spacing-2x-small);
}

.c-document-list__document-card .c-image-list-item__title-section-image {
  width: var(--g-spacing-5x-large);
  height: calc(var(--g-spacing-large) * 3); /* 3.75rem */
  margin-left: var(--g-spacing-2x-small);
}

.c-document-list__document-card .c-image-list-item__title-section-image[src*='pdf'] {
  width: calc(var(--g-spacing-3x-large) + var(--g-spacing-2x-small)); /* 2.75rem */
  height: calc(var(--g-spacing-large) * 3); /* 3.75rem */
  margin-left: var(--g-spacing);
  margin-right: var(--g-spacing-x-small);
  position: relative;
  left: calc(var(--g-spacing-x-small) - var(--g-spacing-3x-small)); /* 0.375rem */
}

.c-document-list__document-card-small .c-image-list-item__title-section-image {
  width: var(--g-spacing-2x-large);
  height: calc(var(--g-spacing-large) * 1.5); /* 3.75rem */
}

.c-document-list__document-card-small .c-image-list-item__title-section-image[src*='pdf'] {
  width: calc(var(--g-spacing-x-large) + var(--g-spacing-3x-small)); /* 2.75rem */
  height: calc(var(--g-spacing-large) * 1.75); /* 3.75rem */
  margin-left: var(--g-spacing-3x-small);
}

.c-document-list__document-card .c-document-card__body .c-document-card__body__document {
  width: 100%;
  height: 8.75rem;
  object-fit: cover;
  object-position: center;
}

.c-image-list-item__title-section__image-container__favorite-icon--small,
.c-document-list-item__title-document-container__favorite-icon--small,
.c-audio-list-item__title-section__audio-container__favorite-button--small .c-icon {
  height: var(--g-spacing-x-large);
  top: 0.225rem;
  position: relative;
}

.c-audio-list-item__title-section__audio-container__favorite-button--small .c-icon {
  height: var(--g-spacing-x-large);
}

.c-audio-list-item__title-section .c-audio-list-item__audio-player--small {
  padding-top: var(--g-spacing-x-small);
}

@media (min-width: 48em) {
  .c-audio-list .c-search-filter__input .c-input,
  .c-video-list .c-search-filter__input .c-input,
  .c-document-list .c-search-filter__input .c-input,
  .c-image-list .c-search-filter__input .c-input {
    min-width: 30rem;
  }

  .c-document-list__document-card {
    padding-top: var(--g-spacing);
  }
}

.c-asset-list-table-image {
  height: 4rem;
  display: flex;
  justify-content: center;
  width: auto;
}

.c-asset-list-table-icon {
  height: var(--g-spacing-2x-large);
  width: var(--g-spacing-2x-large);
}
