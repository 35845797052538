.c-report-option-accordion {
  background-color: var(--g-color-white);
  border: 1px solid var(--g-color-grey-100);
  border-radius: var(--g-border-radius-small);
  transition: border-color var(--g-transition-time) ease-in-out;
  margin-bottom: var(--g-spacing);
}

.c-report-option-accordion__header {
  align-items: center;
  background-color: var(--g-color-grey-100);
  border-bottom: 1px solid transparent;
  display: flex;
  justify-content: space-between;
  padding: var(--g-spacing-x-small) var(--g-spacing);
  position: relative;
  transition: border-color var(--g-transition-time) ease-in-out;
}

.c-report-option-accordion__title {
  font-size: var(--g-font-size-large);
  font-weight: var(--g-font-weight-semibold);
  margin: 0;
  padding: 0;
}

.c-report-option-accordion__icon {
  height: var(--g-spacing-large);
  width: var(--g-spacing-large);
}

.c-report-option-accordion__content-container {
  max-height: 0;
  overflow: hidden;
  transition: max-height var(--g-transition-time) ease-in-out;
}

.c-report-option-accordion__content {
  padding: var(--g-spacing-x-small) var(--g-spacing);
  padding-left: calc(var(--g-spacing-4x-large) - var(--g-spacing-2x-small));
}

.c-report-option-accordion__trigger-icon {
  height: var(--g-spacing-large);
  pointer-events: none;
  transform: rotate(-180deg);
  transition: color var(--g-transition-time) ease-in-out, transform var(--g-transition-time) ease-in-out;
  width: var(--g-spacing-large);
}


.c-report-option-accordion.is-open  .c-report-option-accordion__trigger-icon {
  transform: rotate(0);
}
