.c-content-builder-beta-toggle {
  align-items: center;
  display: flex;
  border: 1px solid var(--g-color-grey-100);
  border-radius: 2em;
}

.c-content-builder__link  {
  border-radius: 2em;
  cursor: pointer;
  color: var(--g-color-grey-300);
  padding: 0 var(--g-spacing-x-small);
}

.c-content-builder__link.is-active {
  background-color: var(--g-color-black);
  color: var(--g-color-white);
}