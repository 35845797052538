.c-post-sidebar-general-view {
  padding-bottom: var(--g-spacing-6x-large);
}

.c-post-sidebar-general-view__add-container {
  display: flex;
  justify-content: flex-end;
}

.c-post-sidebar-general-view__add,
.c-post-sidebar-general-view__add-icon {
  height: var(--g-spacing-2x-large);
  width: var(--g-spacing-2x-large);
}

.c-post-sidebar-general-view__add {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}

.c-post-sidebar-general-view__posts-list {
  border-bottom: 1px solid #ccc;
  max-height: 14.35rem;
  overflow-y: scroll;
  margin: 0;
}

.c-post-sidebar-general-view__posts-list-item {
  align-items: center;
  background-color: var(--g-color-white);
  border: var(--g-border-primary);
  border-bottom: none;
  display: flex;
  margin: 0;
  justify-content: space-between;
  padding: var(--g-spacing-x-small);
  width: 100%;
}

.c-post-sidebar-general-view__posts-list-item-count {
  background-color: var(--g-color-grey-50);
  border: 1px solid #ccc;
  border-top: none;
  align-items: center;
  display: flex;
  padding: var(--g-spacing-small);
  width: 100%;
}
