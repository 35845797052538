.c-social-link {
  align-items: center;
  display: flex;
  gap: var(--g-spacing-x-small);
}

.c-social-link__url {
  width: 40%;
}

.c-social-link__input {
  width: 60%;
}