/* stylelint-disable selector-class-pattern */
.c-app-details__content {
  display: grid;
  grid-template-columns: 1fr 5fr;
}

.c-app-details-card .c-card__header {
  padding: var(--g-spacing-x-small) var(--g-spacing-small);
  font-size: var(--g-font-size-large);
  font-weight: var(--g-font-weight-semibold);
}

.c-app-details-card .c-card__title {
  max-width: 100ch;
}