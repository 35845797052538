/* stylelint-disable selector-class-pattern */
.c-scenario-sidebar-condition-view__add-section {
  display: flex;
  gap: var(--g-spacing-x-small);
  justify-content: flex-end;
}

.c-scenario-sidebar-condition-view__add-section .c-button--danger--tertiary {
  padding: var(--g-spacing-4x-small) var(--g-spacing);
  font-size: var(--g-font-size-small);
}

.c-scenario-sidebar-condition-view__add-icon,
.c-scenario-sidebar-condition-view__add {
  height: var(--g-spacing-2x-large);
  width: var(--g-spacing-2x-large);
}

.c-scenario-sidebar-condition-view__add {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}

.c-scenario-sidebar-condition-view__condition-item {
  align-items: center;
  background-color: var(--g-color-white);
  border: var(--g-border-primary);
  border-bottom: none;
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: var(--g-spacing-x-small);
  width: 100%;
}

.c-scenario-sidebar-condition-view__condition-item-sort {
  display: flex;
}

.c-scenario-sidebar-condition-view__condition-item-sort .c-navigation-button {
  cursor: pointer;
  padding: 0;
  background-color: transparent;
}

.c-scenario-sidebar-condition-view__add-group-section {
  position: relative;
}

.c-scenario-sidebar-condition-view__condition-item-sort .c-navigation-button svg {
  width: var(--g-spacing-2x-large);
  height: var(--g-spacing-2x-large);
}

.c-scenario-sidebar-condition-view__condition-item:first-child {
  border-top-left-radius: var(--g-border-radius-small);
  border-top-right-radius: var(--g-border-radius-small);
}

.c-scenario-sidebar-condition-view__condition-item:last-child {
  border-bottom: var(--g-border-primary);
  border-bottom-left-radius: var(--g-border-radius-small);
  border-bottom-right-radius: var(--g-border-radius-small);
}

.c-scenario-sidebar-condition-view__condition-name {
  align-items: center;
  display: flex;
  gap: var(--g-spacing-x-small);
}

.c-scenario-sidebar-condition-view__search-section {
  position: relative;
}

.c-scenario-sidebar-condition-view__select {
  display: flex;
}

.c-scenario-sidebar-condition-view__tooltip {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}

.c-sidebar-section__toggle-buttons {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.c-sidebar-section__toggle-buttons .c-toggle-buttons {
  max-height: var(--g-spacing-3x-large);
}
