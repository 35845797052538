/* stylelint-disable selector-class-pattern */
.c-content-builder-bulk-action-dialog .c-dialog__content {
  text-align: left;
}

.c-content-builder-bulk-action-dialog__list li {
  list-style: disc;
}

.c-content-builder-bulk-action-dialog__list li p {
  margin: 0;
}
