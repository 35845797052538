/* stylelint-disable property-no-vendor-prefix */
/* stylelint-disable selector-class-pattern */
.c-input-counter__counter {
  align-items: center;
  display: flex;
}

.c-input-counter__button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: relative;
  top: calc(var(--g-spacing-3x-small));
}

.c-input-counter__button svg {
  height: var(--g-spacing-large);
  width: var(--g-spacing-large);
}

.c-input-counter__input {
  font-size: var(--g-font-size-small);
  max-height: var(--g-spacing-2x-large);
  max-width: var(--g-spacing-5x-large);
  padding: 0 1.285rem;
  vertical-align: middle;
}

.c-input-counter__input::-webkit-outer-spin-button,
.c-input-counter__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.c-input-counter__input[type='number'] {
  -moz-appearance: textfield;
}
