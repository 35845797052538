.c-group-access___add-button {
    background: transparent;
    border: none;
    cursor: pointer;
    height: var(--g-spacing);
    width: var(--g-spacing);
    padding-top: var(--g-spacing-x-large);
    padding-right: var(--g-spacing-2x-large);
  }
  
  .c-group-access___add-icon {
    height: var(--g-spacing-2x-large);
    width: var(--g-spacing-2x-large);
  }
  
  .c-group-access___cross-icon {
    max-width: var(--g-spacing-large);
    right: calc(var(--g-spacing-2x-small) * -1);
    top: calc(var(--g-spacing-3x-large) * -1);
  }
  
  .c-group-access___cross-button {
    border: none;
    cursor: pointer;
    position: absolute;
    right: var(--g-spacing);
    top: var(--g-spacing-x-large);
  }
  
  .c-group-access___input {
    padding-left: var(--g-spacing-3x-large) !important;
  }
  
  .c-group-access-items {
    margin: 0;
    max-height: calc(var(--g-spacing-6x-large) * 6 + 1.4rem);
    overflow-y: auto;
    padding: 0;
  }
  
  .c-group-access-item,
  .c-group-access-item__count {
    align-items: center;
    border: var(--g-border-primary);
    border-width: var(--g-spacing-3x-small);
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: var(--g-spacing-x-small) var(--g-spacing);
  }
  
  .c-group-access-item:first-child {
    border-top-left-radius: var(--g-border-radius-small);
    border-top-right-radius: var(--g-border-radius-small);
  }
  
  .c-group-access-item:not(:first-child) {
    border-top: none;
  }
  
  .c-group-access-item__avatar {
    width: var(--g-spacing-2x-large);
    height: var(--g-spacing-2x-large);
  }
  
  .c-group-access-item__content {
    display: flex;
    align-items: center;
    gap: var(--g-spacing);
  }
  
  .c-group-access-item__count {
    background-color: var(--g-color-grey-50);
    border-bottom-left-radius: var(--g-border-radius-small);
    border-bottom-right-radius: var(--g-border-radius-small);
    padding: var(--g-spacing);
  }
  
  .c-group-access__icon {
    width: var(--g-spacing);
    height: var(--g-spacing);
  }
  
  .c-group-access-item__label {
    font-size: var(--g-font-size);
  }
  
  .c-group-access___search {
    position: relative;
    bottom: var(--g-spacing-small);
  }
  
  .c-group-access___search-icon,
  .c-group-access___cross-icon {
    position: absolute;
  }
  
  .c-group-access___search-icon {
    left: var(--g-spacing-small);
    max-width: var(--g-spacing-large);
    top: calc((var(--g-spacing) + var(--g-spacing-3x-small)) * -1);
  }
  