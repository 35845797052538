.c-caption-form-view {
  padding: var(--g-spacing) var(--g-spacing-2x-large) var(--g-spacing-2x-large) !important;
}

.c-caption-form-view_form__times {
  font-size: var(--g-font-size-small);
}

.c-caption-form-view_form__times .h-spacing-small {
  margin-bottom: 0;
}
