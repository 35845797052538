.c-user-sidebar-general-view__posts-list {
  border-bottom: 1px solid #ccc;
  max-height: 14.35rem;
  overflow-y: scroll;
  margin: 0;
}

.c-user-sidebar-general-view__posts-list-item {
  list-style-type: none;
  border: 1px solid #ccc;
  border-bottom: none;
  margin: 0;
  padding: var(--g-spacing-x-small) var(--g-spacing-small);
}

.c-user-sidebar-general-view__posts-list-item-count {
  background-color: var(--g-color-grey-50);
  border: 1px solid #ccc;
  border-top: none;
  align-items: center;
  display: flex;
  padding: var(--g-spacing-small);
  width: 100%;
}
