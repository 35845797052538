/* stylelint-disable selector-class-pattern */
.c-scenario-status {
  align-items: center;
  display: flex;
  gap: var(--g-spacing-x-small);
}

.c-scenario__status {
  border-radius: var(--g-border-radius-full);
  height: calc(var(--g-spacing-small) - 0.05rem);
  width: calc(var(--g-spacing-small) - 0.05rem);
}

.c-scenario__status--active {
  background-color: var(--g-color-warning);
}

.c-scenario__status--not-active {
  background-color: var(--g-color-error);
}

/* TODO: Add horizontal version to the Toggle switch and remove this line */
.c-scenario-status-toggle .c-label {
  align-items: center;
  display: flex;
  font-weight: var(--g-font-weight-bold);
  gap: var(--g-spacing-small);
  margin-bottom: 0;
}

/* TODO: Add horizontal version to the Toggle switch and remove this line */
.c-scenario-status-toggle .c-label .h-spacing-small {
  margin-bottom: 0;
}
